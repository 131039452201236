window.changeHoursEditButtonContent = function (columnId) {
    event.preventDefault();

    const hoursEditButton = document.querySelector(`#hours-edit-btn-${columnId}`);
    const hoursContactInput = document.querySelector(`#hours-contact-input-${columnId}`);
    const hoursClosedInput = document.querySelector(`#hours-closed-input-${columnId}`);
    const hoursFullTimeInput = document.querySelector(`#hours-fulltime-input-${columnId}`);
    const editHoursFromInput = document.querySelector(`#edit-hours-from-${columnId}`);
    const editHoursToInput = document.querySelector(`#edit-hours-to-${columnId}`);

    let hoursFromValue = editHoursFromInput.value;
    let hoursToValue = editHoursToInput.value;

    if (hoursContactInput.checked) {
        hoursEditButton.innerHTML = "DO UZGODNIENIA";
    }

    if (hoursClosedInput.checked) {
        hoursEditButton.innerHTML = "NIECZYNNE";
    }

    if (hoursFullTimeInput.checked) {
        hoursEditButton.innerHTML = "24H";
    }

    if (!hoursContactInput.checked && !hoursClosedInput.checked && !hoursFullTimeInput.checked) {
        hoursEditButton.innerHTML = (hoursFromValue.length === 0) || (hoursToValue.length === 0)
            ? 'Dodaj godziny'
            : `${hoursFromValue} - ${hoursToValue}`;
    }
}
